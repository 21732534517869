<template>
    <div class="FormBox">
        <van-form  @submit="onSubmit"  ref="Form" :scroll-to-error='true' :show-error='false'>
            <div class="EventBox" >
                <h4 class="FormBoxTitle">试剂耗材预约</h4>
                <van-cell-group>
                    <van-field @input="$emit('ModelChange',TestValue)" required v-model="TestValue.LA_ReagentClassification"  label="试剂/耗材分类"  placeholder="试剂/耗材分类"  :rules="[{ required: true, message: '试剂/耗材分类' }]" />
                </van-cell-group>
                <div class="BoxRow">
                    <h4>试剂/耗材名称、数量</h4>
                    <van-field @input="$emit('ModelChange',TestValue)" v-model="TestValue.LA_QuantityOfReagents"  placeholder="试剂/耗材名称、数量" 
                    class=""
                     type="textarea"
                     autosize
                     rows="4"
                    :rules="[{ required: true, message: '试剂/耗材名称、数量' }]"
                    />
                </div>
                <div class="BoxRow">
                    <h4>试剂/耗材的包装要求</h4>
                    <van-field @input="$emit('ModelChange',TestValue)" v-model="TestValue.LA_PackagingRequirements"  placeholder="试剂/耗材的包装要求" 
                    class=""
                     type="textarea"
                     autosize
                     rows="4"
                    :rules="[{ required: true, message: '试剂/耗材的包装要求' }]"
                    />
                </div>
                <div class="BoxRow tpsBox">
                    <h4 class="Tips">试剂/耗材预约说明</h4>
                    <p>1)、实验室使用单位可要求实验室帮助准备试验所需且合乎规定的试剂耗材设备</p>
                    <p>2)、危险化学品应由使用单位自行办理，实验室管理人员不得代办、不得协助</p>
                </div>
            </div>
        </van-form>
        <div class="InfButtom van-hairline--top">
            <van-button  type="primary" block @click="NextClick">下一步</van-button>
        </div>
    </div>
</template>
<script>
import { Form,CellGroup,Field,Button ,Icon  } from 'vant';
export default {
    model:{
        prop: 'TestValue',
        event: 'ModelChange'
    },
    props:{
        TestValue:{
            type:Object
        }
    },
    components:{
       
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button,
        [Icon.name]: Icon,
    },
    data() {
        return {
          Instrument:[1,],
           value:'',
        }
    },
    methods:{
        onSubmit(){
            
            this.$router.push({path:'/makeAppointment/laboratoryProtect',query:{fromPath:this.$route.query.fromPath}})
        },
        NextClick(){
           this.$refs.Form.submit()
        }
    }
}
</script>

<style lang="scss" scoped>
.FormBox {
    flex: 1;
    display: flex;
    height: calc(100vh - 46px);
    flex-direction: column;
    .van-form {
        flex: 1;
        padding-bottom: 10px;
        overflow-y: auto;
    }
    .EventBox {
        padding-top: 10px;
        background: #f2f2f2;
        .FormBoxTitle {
            font-size: 14px;
            background: #fff;
            padding: 8px 14px;
            color: #333;
        }  
    
    }
    .BoxRow {
        background: #fff;
        margin-top: 8px;
        h4 {
            font-size: 14px;
            color: #333;
            padding: 0px 14px;
            padding-top: 10px;
        }
        /deep/ .van-field {
            padding: 10px 10px;
        }
        /deep/ .van-field__body {
            border: 1px solid #e6e6e6;
            border-radius: 5px;
            padding:5px;
        }
        p {
            font-size: 13px;
            color: #ee0a24;
            padding: 0 14px;
            line-height: 22px;
        }
        .Tips {
            padding-bottom: 10px;
        }
        
    }
    .tpsBox {
        padding-bottom: 10px;
    }
}
.InfButtom {
    padding: 10px 14px;
    background-color: #fff;

    .van-button {
        line-height: 38px;
        height: 38px;
    }
}
</style>